@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}

.asset-toggle-btn {
  text-align: left !important;
}

.avia_textblock a {
  color: white;
}

.toggle-hover {
  text-align: left !important;
}

.toggle-hover:hover {
  background-color: 'rgba(228, 12, 126, 0.2)' !important;
}

.brightenImage {
  filter: brightness(1.7);
}

@font-face {
  font-family: 'Questrial';

  font-weight: 400;

  font-style: normal;

  src: local('Questrial'), url(/_next/static/media/Questrial-Regular.f50870bd.ttf) format('truetype');
}

